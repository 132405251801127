<template>
  <div>
    <sidebar-menu @toggle-collapse="toggleMainMenu" ref="sideBarMenu" :menu="menu" :collapsed="collapsed" :showChild="false" :disableHover="true">
      <span slot="toggle-icon"><font-awesome-icon :icon="['fas', 'bars']" /></span>
      <span slot="dropdown-icon"><font-awesome-icon :icon="['fas', 'angle-right']" /></span>
    </sidebar-menu>
  </div>
</template>
<script>
import router from "../../router";
import VueRouter from "vue-router";
import Vue from "vue";
import MenuMixins from "../../mixins/MenuMixins.vue";

import { SidebarMenu } from "vue-sidebar-menu";
import { isMobile } from "mobile-device-detect";

/*
import "vue-sidebar-menu/dist/vue-sidebar-menu.css";
*/
import "vue-sidebar-menu/src/scss/vue-sidebar-menu.scss";
import "font-awesome/scss/font-awesome.scss";

export default {
  name: "MainMenu",
  mixins: [MenuMixins],
  components: {
    SidebarMenu,
  },
  router,
  methods: {
    toggleMainMenu: function () {
      this.trackCollapseEvent("Main Menu Toggle - Bottom of Menu");
    },

    trackCollapseEvent: function (categoryName) {
      let label = this.collapsed ? "open" : "close";
      this.$ga.event(categoryName, "click", label, 0);
      this.onToggleCollapse();
    },
    onToggleCollapse: function () {
      this.collapsed = !this.collapsed;
      this.$root.isMenuExpanded = !this.$root.isMenuExpanded;
    },
  },
  props: {
    isAdmin: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      collapsed: !this.$root.isMenuExpanded,
      menu: [
        {
          href: { path: "/form-responses/form-responses" },
          title: "Dashboard",
          icon: {
            element: "font-awesome-icon",
            attributes: {
              icon: "flag-checkered",
            },
          },
        },
        {
          title: "Responses",
          icon: {
            element: "font-awesome-icon",
            attributes: {
              icon: "gem",
            },
          },
          child: [
            {
              href: { path: "/form-responses/form-responses" },
              title: "Form Responses",
              icon: {
                element: "font-awesome-icon",
                attributes: {
                  icon: "database",
                },
              },
            },
            {
              href: { path: "/form-responses/respondent-download" },
              title: "Respondent Download",
              icon: {
                element: "font-awesome-icon",
                attributes: {
                  icon: "user",
                },
              },
            },
          ],
        },
        {
          title: "Form Design",
          icon: {
            element: "font-awesome-icon",
            attributes: {
              icon: "wrench",
            },
          },
          child: [
            {
              href: { path: "/form-design/form-design" },
              title: "Form Design",
              icon: {
                element: "font-awesome-icon",
                attributes: {
                  icon: "tasks",
                },
              },
            },
            {
              href: { path: "/form-design/form-preview" },
              title: "Form Preview",
              icon: {
                element: "font-awesome-icon",
                attributes: {
                  icon: "eye",
                },
              },
            },
            {
              href: { path: "/study-design/manage-invitations" },
              title: "Manage Forms",
              icon: {
                element: "font-awesome-icon",
                attributes: {
                  icon: "envelope",
                },
              },
            },
          ],
        },
        {
          title: "Company Info",
          icon: {
            element: "font-awesome-icon",
            attributes: {
              icon: "building",
            },
          },
          child: [
            {
              href: { name: "user-settings" },
              title: "User Settings",
              icon: {
                class: "mr-2",
                element: "font-awesome-icon",
                attributes: {
                  icon: "address-card",
                },
              },
            },
          ],
        },
        {
          href: "/contact-us",
          title: "Support",
          icon: {
            element: "img",
            attributes: {
              src: "/img/hand-logo.png",
              width: "30",
              height: "30",
            },
          },
        },
      ],
      adminMenu: [
        {
          title: "Administration",
          icon: {
            element: "font-awesome-icon",
            attributes: {
              icon: "cogs",
            },
          },
          child: [
            {
              href: "/admin/form-list",
              title: "Form List",
              icon: {
                element: "font-awesome-icon",
                attributes: {
                  icon: "th-list",
                },
              },
            },
            {
              href: { path: "/admin/add-user" },
              title: "Add User",
              icon: {
                element: "font-awesome-icon",
                attributes: {
                  icon: "user-plus",
                },
              },
            },
            {
              href: { path: "/admin/content-list" },
              title: "Content List",
              class: "sidebar-adjustment content-list-item",
              icon: {
                element: "font-awesome-icon",
                attributes: {
                  icon: "th-list",
                },
              },
            },
            {
              href: { path: "/admin/user-list" },
              title: "User List",
              icon: {
                element: "font-awesome-icon",
                attributes: {
                  icon: "th-list",
                },
              },
            },
          ],
        },
      ],
    };
  },
  created() {
    if (this.isAdmin) {
      this.menu = this.menu.concat(this.adminMenu);
    }
  },
  mounted() {},
  watch: {
    $route() {
      this.$refs.sideBarMenu.$data.mobileItem = null;
    },
  },
  computed: {},
};
</script>
