<template>
  <div class="panel-admin-body">
    <div class="d-flex title">
      <div class="pb-1"><modal-link modal-id="How to Use"></modal-link>{{ title }}</div>
    </div>
    <div class="response-summary container">
      <div class="d-flex">
        <div class="form-group form-inline">
          <label for="startDate" class="mr-1">Start Date</label>
          <datepicker format="MMM dd yyyy" v-model="startDate" :typeable="true" wrapper-class="" input-class="form-control required w-100 bg-white" name="startDate"></datepicker>
        </div>
        <div class="form-group form-inline ml-2">
          <label for="endDate" class="mr-1">End Date</label>
          <datepicker format="MMM dd yyyy" v-model="endDate" :typeable="true" wrapper-class="" input-class="form-control required w-100 bg-white" name="endDate"></datepicker>
        </div>
      </div>
      <div class="d-flex">
        <div class="form-group form-inline" style="width: 367px">
          <label for="status" class="mr-2 mt-2">Status</label>
          <div class="w-75">
            <v-select id="status" name="status" :options="statusOptions" :reduce="(statusOption) => statusOption.status" label="description" :clearable="false" v-model="status">
            </v-select>
          </div>
        </div>
        <div class="pr-2">
          <button type="button" class="btn btn-primary ml-2" id="AddNewContentButton" @click="getTableData(true)">Filter</button>
        </div>
      </div>
      <table :id="table.id" :class="table.class" cellspacing="0" width="100%">
        <thead></thead>
        <tbody></tbody>
      </table>
      <div v-for="(pageContent, idxPageContent) in pageContent" v-bind:key="idxPageContent">
        <modal :modal-id="pageContent.title" :modal-title="pageContent.title" :modal-content="pageContent.contentText" />
      </div>
      <survey-responses ref="surveyResponsesModal" :surveyTakerId="currentSurveyTakerId" :surveyId="currentSurveyId"/>
    </div>
  </div>
</template>
<script>
import $ from "jquery";
import RespondentMixins from "../../mixins/RespondentMixins.vue";
import DataTableMixins from "../../mixins/DataTableMixins.vue";
import Datepicker from "vuejs-datepicker";
import SurveyResponses from "../../modals/SurveyResponses.vue";

import ResponseService from "../../services/response.service";

export default {
  name: "Responses",
  mixins: [RespondentMixins, DataTableMixins],
  components: { Datepicker,SurveyResponses },
  props: {},
  data() {
    return {
      table: {
        id: "response-summary-data",
        columns: ResponseService.getResponseSummaryTableColumnData(),
      },
      pageNumber: 12,
      startDate: new Date(2014, 1, 1),
      endDate: new Date(),
      status: "C",
      statusOptions: [
        { status: "A", description: "All" },
        { status: "C", description: "Complete" }, 
        { status: "I", description: "Incomplete" },
      ],
      title: "Responses",
      currentSurveyId: 0,
      currentSurveyTakerId: ""
    };
  },
  methods: {
    async getTableData(redrawTable) {
      this.$root.setLoading(true);
      if (redrawTable){
        ResponseService.getResponseSummary(this.startDate, this.endDate, this.status, this.redrawTable, this);
      } else {
        ResponseService.getResponseSummary(this.startDate, this.endDate, this.status, this.getTableDataCallback, this);
      }
    },
    loadData: function () {
      this.getTableData(false);
      this.table.configuration.order = [[5, "desc"]];
      this.table.configuration.dom = "frtpB";
    },
    redrawTable: function(response){
      this.$root.setLoading(false);
      this.table.data = response.data.data;      
      this.table.dataTable.clear().rows.add(this.table.data).draw();
    }
  },
  created() {},
  mounted() {
    let vm = this;
    $("#response-summary-data tbody").on("click", 'button[type="button"]', function (e, dt, type, indexes) {
      var $row = $(this).closest("tr");
      var data = vm.table.dataTable.row($row).data();
      if (typeof data != "undefined"){
        vm.currentSurveyTakerId = data.surveyTakerId;
        vm.currentSurveyId = data.surveyId;
      }
      if (vm.currentSurveyId != 0){
        vm.$refs.surveyResponsesModal.showSurvey = true;
      }
    });
  },
  watch: {},
  computed: {},
};
</script>
