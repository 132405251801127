<template>
  <div v-if="isLoggedIn" id="app">
    <notifications group="admin" position="top center" />
    <div class="admin-layout">
      <div class="main-navbar navbar fixed-top my-0 py-0">
        <router-link :to="{ name: 'form-responses'}">
          <img src="./assets/img/wrist-and-hand-center-tampa-logo-2.png" width="130" height="42" alt="Admin" />
        </router-link>
        <ul class="main-navbar-menu nav align-self-stretch flex-nowrap">
          <li class="nav-item dropdown">
            <a class="line-height-1 d-flex align-items-center h-100 text-left nav-link dropdown-toggle" data-toggle="dropdown" href="#">
              <img class="personalProfilePhoto logo-image mr-1" :src="logoImage" alt />
              <span class="d-none d-md-inline">
                <small class="d-flex">Welcome,</small>
                {{ fullName }}
              </span>
            </a>
            <ul class="dropdown-menu dropdown-menu-right">
              <li>
                <router-link class="dropdown-item" :to="{ name: 'user-settings', params: { initialUserId: this.currentUser.id } }">
                  <i class="fa fa-lg fa-fw fa-user"></i>
                  User Settings
                </router-link>
              </li>
              <li class="dropdown-divider"></li>
              <li>
                <router-link class="dropdown-item" :to="{ name: 'logout' }">
                  <font-awesome-icon :icon="['fas', 'sign-out-alt']" size="lg" />
                  <span class="pl-2">Log Out</span>
                </router-link>
              </li>
            </ul>
          </li>
        </ul>
      </div>
      <div :class="[!this.$root.isMenuExpanded ? 'panel-admin-expanded container-fluid' : 'panel-admin container-fluid']">
        <main-menu ref="mainMenu" :is-admin="isAdmin"></main-menu>
        <bread-crumb />
        <div class="organization-survey-header">
            <treeselect v-model="treeValue" 
            @select="changeClientId"
            :multiple="false" 
            :show-count="true"
            :options="clientOptions" 
            :append-to-body="true" 
            :required="true"
            :clearable="false"
            :maxHeight="400"
            placeholder="Search..." />

          <div v-show="displaySurveyWidget">
            <div class="d-flex survey-display">
              <label for="survey_id">Survey</label>
              <div class="w-100">              
                <v-select 
                  id="surveyCode" 
                  name="surveyCode" 
                  :disabled="disableSurveyDropdown"
                  :options="clientObject.surveyCodes" 
                  :reduce="survey => survey.surveyCode" 
                  label="name"
                  :clearable="false"
                  required
                  v-model="surveyCode">
                </v-select>
              </div>
            </div>
          </div>
        </div>
        <router-view />
      </div>
      <div class="main-footer-bar">
        <div class="footer-content">
          <a href="https://www.wristandhandcenterfl.com" target="_blank">
            <span>© {{ new Date().getFullYear() }} wristandhandcenterfl.com (BETA Version)</span>
          </a>
        </div>
      </div>
    </div>
  </div>
  <div v-else>
    <router-view></router-view>
  </div>
</template>
<script>
import Vue from "vue";
import MainMenu from "./views/menu/MainMenu.vue";
import axios from "axios";
import jQuery from "jquery";
import BreadCrumb from "./components/BreadCrumb.vue";


import authHeader from "./services/auth-header";
import ClientService from "./services/client.service";
import ErrorService from "./services/error.service";
import AdminService from "./services/admin.service";
import SurveyService from "./services/survey.service";
import UserService from "./services/user.service";
import ViewMixins from "./mixins/ViewMixins.vue";
import Treeselect from "@riophae/vue-treeselect";

import Clipboard from "v-clipboard";
import VueLogger from "vuejs-logger";
import { faWindowClose } from "@fortawesome/free-solid-svg-icons/faWindowClose";
import { isMobile } from "mobile-device-detect";
import $ from "jquery";
import VueAnalytics from "vue-analytics";
import router from "./router";
import vSelect from "vue-select";
Vue.component("v-select", vSelect);
import "vue-select/dist/vue-select.css";

import "@riophae/vue-treeselect/dist/vue-treeselect.css";
Vue.component("treeselect", Treeselect);


const options = {
  isEnabled: true,
  logLevel: typeof process.env.VUE_APP_LOG_LEVEL != "undefined" ? process.env.VUE_APP_LOG_LEVEL : "debug",
  stringifyArguments: false,
  showLogLevel: true,
  showMethodName: true,
  separator: "|",
  showConsoleColors: true
};

Vue.use(VueLogger, options);
Vue.use(Clipboard);
// Configuration VueAnalytics
Vue.use(VueAnalytics, {
  id: process.env.VUE_APP_GA_ANALYTICS_ADMIN,
  router,
  debug: {
    enabled: false,
    sendHitTask: true
  },
  autoTracking: {
    exception: true
  }
});

import "./sass/_admin.scss";
export default {
  name: "App",
  components: {
    MainMenu,
    BreadCrumb,
    Treeselect
  },
  mixins: [ViewMixins],
  data() {
    return {
      treeValue: null,
      clientId: 0,
      clientObject: {},
      clientOptions: [],
      message: "",
      dataLoaded: false,
      surveyCode: "",
      logoImage: "",
      displayShowMenuIcon: false,
      disableSurveyDropdown: false,
    };
  },
  methods: {
    toggleMainMenu: function(){
      this.$refs.mainMenu.trackCollapseEvent('Main Menu Toggle - Header');
    },
    toggleHeaderMenu: function(){
      let label = this.$root.showMenu ? "close" : "open";
      this.$ga.event("Header Menu Toggle", "click", label, 0);
      this.$root.toggleHeaderMenu();
    },
    changeSurveyCodeValue: function(value) {
        this.surveyCode = value;
    },
    refreshPage: function () {
      this.$notify(AdminService.displayInfoMessage({ text: "Refreshing page please stand by......" }));
      this.$router.go();
    },
    getAllClientsCallback(data) {
      this.clientOptions = data;
      this.dataLoaded = true;
    },
    changeClientId(event) {
      this.changeClientIdValue(event.target.value);
    },
    async changeClientIdValue(value) {
      this.$root.setLoading(true);
      this.$notify(AdminService.displayInfoMessage({ text: "Retrieving client please stand by......" ,duration: -1}));
      await ClientService.changeClient(this.baseUrl, value, this.changeClientCallback, this);
    },
    changeClientCallback(response) {
      this.$root.setLoading(false);
      let statusCode = response.data.statusCode;
      if (statusCode == 200) {
        this.$notify(AdminService.displaySuccessMessage({ text: response.data.message }));
        this.clientObject = JSON.parse(response.data.data);
        this.clientId = this.clientObject.clientId;
        ClientService.saveClient(this.clientObject);
        this.surveyCode = SurveyService.resetSurveyCode(this.clientObject);
        this.dataLoaded = true;
      } else {
        this.$notify(AdminService.displayErrorMessage({ text: response.data.message }));
      }
      this.$notify({ group: "admin", clean: true });
    },
    loadData() {
        ClientService.getAllClients(this.getAllClientsCallback);
      this.clientObject = ClientService.getClient();
      this.clientId = this.clientObject.clientId;
      this.treeValue = this.clientId;
      this.loadSurveyData();
      this.dataLoaded = true;
      this.logoImage = this.userImage;
    },
    loadSurveyData() {
      let savedSurveyCode = SurveyService.getSurveyCode();
      if (savedSurveyCode != "" && SurveyService.contains(savedSurveyCode)) {
        this.surveyCode = savedSurveyCode;
      } else {
        this.surveyCode = SurveyService.resetSurveyCode(this.clientObject);
      }
    },
    async handleLogout() {
      this.dataLoaded = false;
      this.$store.dispatch("auth/logout");
      await this.$auth.logout();
    }
  },
  mounted() {},
  created() {
    this.$log.info("Starting " + process.env.VUE_APP_TITLE);
    this.$log.info("Base URL  " + this.baseUrl);
    this.$log.info("Base Interview URL  " + process.env.VUE_APP_INTERVIEW_URL);
    this.loadData();
  },
  watch: {
    currentUser: function () {
      this.loadData();
    },
    clientObject: function () {
      ClientService.saveClient(this.clientObject);
    },
    surveyCode: function () {
      if (this.surveyCode != "") {
        SurveyService.setSurveyCode(this.surveyCode);
      }
    }
  },
  destroyed() {},
  computed: {
    surveyOptions: function() {
      let options = [];
      for (let surveyIndex=0; surveyIndex<this.clientObject.surveyCodes.length; surveyIndex++){
        let newSurveyOption = new Object();
        newSurveyOption.id = this.clientObject.surveyCodes[surveyIndex].surveyCode;
        newSurveyOption.label = this.clientObject.surveyCodes[surveyIndex].name;
        options.push(newSurveyOption);
      }
      return options;
    },    
    displaySurveyWidget: function () {
      if (!jQuery.isEmptyObject(this.clientObject.surveyCodes)) {
        return this.clientObject.surveyCodes.length > 1;
      }
      return false;
    },
    isLoggedIn: function () {
      return this.$store.state.auth.status.loggedIn;
    },
    isAdmin: function () {
      if (this.currentUser && this.currentUser.userTypeName) {
        return this.currentUser.userTypeName == "Admin";
      } else {
        return false;
      }
    },
    isAdvancedUser: function () {
      if (this.currentUser && this.currentUser.userTypeName) {
        return this.currentUser.userTypeName == "Advanced Client";
      } else {
        return false;
      }
    },
    isPowerUser: function() {
      if (this.currentUser && this.currentUser.userTypeName) {
        return this.currentUser.userTypeName == "Power User";
      } else {
        return false;
      }
    },
    isBasicUser: function() {
      if (this.currentUser && this.currentUser.userTypeName) {
        return this.currentUser.userTypeName == "Basic Client";
      } else {
        return false;
      }
    },
    currentUser: function () {
      if (this.isLoggedIn) {
        return this.$store.state.auth.user.user;
      } else {
        return null;
      }
    },
    userImage: function () {
      if (this.clientId != 0) {
        return this.baseUrl + "/api/v1/content/get-logo-by-client-id?clientId=" + this.clientId + "&viewType=inline&userId=" + UserService.getUserId();
      } else {
        return "";
      }
    },
    clientName: function () {
      return this.clientObject.name;
    },
    fullName: function () {
      if (this.currentUser && this.currentUser.firstName && this.currentUser.lastName) {
        return this.currentUser.firstName + " " + this.currentUser.lastName;
      } else {
        return "";
      }
    }
  },
  props: {
    baseUrl: {
      type: String,
      default: process.env.VUE_APP_BASE_URL
    }
  }
};
window.onerror = function (message, source, lineno, colno, error) {
  var string = "";
  if (message.toLowerCase) {
    string = message.toLowerCase();
  }
  var substring = "script error";
  if (string.indexOf(substring) > -1) {
    alert("Script Error: See Browser Console for Detail");
  } else {
    var customMessage = ["Message: " + message, "source: " + source, "Line: " + lineno, "Column: " + colno, "Error object: " + JSON.stringify(error)].join(" - ");
    ErrorService.logException(source, customMessage, "window");
  }
  return false;
};
</script>
