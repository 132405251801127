<template>
  <div v-if="isContent" class="form-row " v-html="contentDisplay">
  </div>
  <div v-else-if="isPhoneNumber" class="form-row">
    <label :for="questionId" class="col-form-label col-4 text-right">{{ questionLabel }}</label>
    <div class="col-8">
      <input
        v-model="value"
        v-show="!readOnly"
        :disabled="readOnly"
        type="tel"
        :class="[questionObject.isRequired == 'Y' ? 'form-control required ' : 'form-control']"
        :id="questionId"
        :name="questionName"
        @blur="saveResponseEvent($event)"
        :placeholder="questionObject.widgetConfiguration.placeholder"
      /><div class="pt-2" v-show="readOnly">{{ value }}</div>
      <small v-show="!readOnly">Format: {{ questionObject.widgetConfiguration.placeholder }}</small>
    </div>
  </div>
  <div v-else-if="isTextBox" class="form-row">
    <label :for="questionId" class="col-form-label col-4 text-right">{{ questionLabel }}</label>
    <div class="col-8">
      <input
        v-model="value"
        v-show="!readOnly"
        type="text"
        :class="[questionObject.isRequired == 'Y' ? 'form-control required ' : 'form-control']"
        :id="questionId"
        :name="questionName"
        @blur="saveResponseEvent($event)"
        :placeholder="questionObject.widgetConfiguration.placeholder"
      /><div class="pt-2" v-show="readOnly">{{ value }}</div>
    </div>
  </div>
  <div v-else-if="isEmail" class="form-row">
    <label :for="questionId" class="col-form-label col-4 text-right">{{ questionLabel }}</label>
    <div class="col-8">
      <input
        type="email"
        v-show="!readOnly"
        v-model="value"
        :disabled="readOnly"
        :class="[questionObject.isRequired == 'Y' ? 'form-control required ' : 'form-control']"
        aria-describedby="emailHelp"
        :id="questionId"
        :name="questionName"
        @blur="saveResponseEvent($event)"
        :placeholder="questionObject.widgetConfiguration.placeholder"
      />/><div class="pt-2" v-show="readOnly">{{ value }}</div>
      <small id="emailHelp" class="form-text text-muted">We do not share your email unless you give us explicit permission to show it.</small>
    </div>
  </div>
  <div v-else-if="isTextArea" class="form-row">
    <label :for="questionId" class="col-12">{{ questionLabel }}</label>
    <textarea
      v-show="!readOnly"
      :class="[questionObject.isRequired == 'Y' ? 'form-control required ' : 'form-control']"
      :id="questionId"
      :name="questionName"
      @blur="saveResponseEvent($event)"
      :rows="questionObject.widgetConfiguration.rows"
    ></textarea>
    <div class="pl-2" v-show="readOnly">{{ value }}</div>
  </div>
  <div v-else-if="isRadio && !displayLabels" class="form-row">
    <label class="col-3 form-check-label" :for="questionId">
      {{ questionLabel }}
    </label>
    <div class="col-9 form-check form-check-inline">
      <input
        type="radio"
        :disabled="readOnly"
        v-model="value"
        v-for="(radioOption, idxRadio) in questionObject.widgetConfiguration.options"
        v-bind:key="idxRadio"
        :class="[questionObject.isRequired == 'Y' ? 'form-check-input col-lg-1 required ' : 'form-check-input col-lg-1']"
        :checked="radioOption.checked"
        :id="questionId"
        :name="questionId"
        :value="radioOption.value"
        @click="saveResponseEvent($event)"
      />
    </div>
  </div>
  <div v-else-if="isRadio && displayLabels" class="form-row">
    <label class="col-4 form-check-label" :for="questionId">
      {{ questionLabel }}
    </label>
    <div class="col-8 form-check form-check-inline">
      <div class="question-radio-label form-row">
        <div v-show="!readOnly" v-for="(option, idxOption) in questionObject.widgetConfiguration.options" :class="radioClass" v-bind:key="idxOption">
          <label class="clickable">
            <input
              type="radio"
              v-model="value"
              :disabled="readOnly"
              title="This field is required."
              :class="[questionObject.isRequired == 'Y' ? 'form-check-input required ' : 'form-check-input']"
              :checked="option.checked"
              :id="questionId"
              :name="questionId"
              :value="option.value"
              @click="saveResponseEvent($event)"
            />
            {{ option.name }}
          </label>
        </div>
        <div v-show="readOnly" class="form-check-inline p-2">{{ getCheckboxValue(value) }}</div>
      </div>
    </div>
  </div>
  <div v-else-if="isSlider" class="form-row">
    <label class="col-3 text-right">{{ leftLabel }}</label>
    <div class="col-6">
      <input
        type="text"
        :class="[questionObject.isRequired == 'Y' ? 'form-control required ' : 'form-control']"
        :id="questionId"
        :name="questionName"
        :data-slider-min="questionObject.widgetConfiguration.min"
        :data-slider-max="questionObject.widgetConfiguration.max"
        :data-slider-step="questionObject.widgetConfiguration.step"
        :data-slider-value="questionObject.widgetConfiguration.value"
        :data-slider-tooltip="'always'"
        :data-slider-tooltip_position="'bottom'"
        
      />
      <p class="help">Basic example with custom formatter and colored selected region via CSS.</p>
    </div>
    <label class="col-3">{{ rightLabel }}</label>
  </div>
  <div v-else-if="isDropdown" class="form-row form-group">
    <label :class="leftDropdownClass" :for="questionId">
      {{ questionLabel }}
    </label>
    <div :class="rightDropdownClass">
      <select
        v-model="value"
         v-show="!readOnly"
        :disabled="readOnly"
        title="This field is required."
        :class="[questionObject.isRequired == 'Y' ? 'form-control required ' : 'form-control']"
        :id="questionId"
        :name="questionName"
        @change="saveResponseEvent($event)"
      >
        <option
          v-for="(dropdownOption, idxDropdown) in questionObject.widgetConfiguration.options"
          :selected="dropdownOption.selected"
          :value="dropdownOption.value"
          v-bind:key="idxDropdown"
        >
          {{ dropdownOption.name }}
        </option>
      </select>
      <div class="pt-2" v-show="readOnly">{{ value }}</div>
    </div>
  </div>
  <div v-else-if="isCheckbox" class="form-row">
    <label class="col-4 form-check-label" :for="questionId">
      {{ questionLabel }}
    </label>
    <div class="col-8 form-check form-check-inline">
      <div class="question-radio-label form-row">
        <div v-show="!readOnly" v-for="(checkBoxOption, idxCheckbox) in questionObject.widgetConfiguration.options" :class="radioClass" v-bind:key="idxCheckbox">
          <label class="clickable">
            <input
              type="checkbox"
              :checked="values.includes(checkBoxOption.value)"
              :disabled="readOnly"
              :class="[questionObject.isRequired == 'Y' ? 'form-check-input required ' : 'form-check-input']"
              :name="questionId"
              :value="checkBoxOption.value"
              @click="saveResponseEvent($event)"
            />
            {{ checkBoxOption.name }}
          </label>
        </div>
      </div>
      <div v-if="readOnly" class="question-radio-label d-flex flex-row flex-wrap">
        <div v-for="(readOnlyCheckBoxOption, idxReadOnlyCheckbox) in values" class="form-check-inline p-1" v-bind:key="idxReadOnlyCheckbox">
            <span>{{ getCheckboxValue(readOnlyCheckBoxOption) }}</span>
            <span v-if="idxReadOnlyCheckbox != (values.length-1)">, </span>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import $ from "jquery";
import axios from "axios";
import ErrorService from "../../services/error.service";

import bFormSlider from "vue-bootstrap-slider/es/form-slider";
import "bootstrap-slider/dist/css/bootstrap-slider.css";
import QuestionMixins from "../../mixins/QuestionMixins.vue";

export default {
  name: "SurveyTakerQuestion",
  mixins: [QuestionMixins],
  data() {
    return {
      value: "",
      values: [],
      initialLoad: true,
      emotionalNeedsOptions: { min: 6, max: 605, step: 1, value: 308 },
      functionalNeedsOptions: [
        { name: "N/A", value: "0", checked: true },
        { name: "1", value: "1", checked: false },
        { name: "2", value: "2", checked: false },
        { name: "3", value: "3", checked: false },
        { name: "4", value: "4", checked: false },
        { name: "5", value: "5", checked: false },
        { name: "6", value: "6", checked: false },
        { name: "7", value: "7", checked: false },
        { name: "8", value: "8", checked: false },
        { name: "9", value: "9", checked: false },
        { name: "10", value: "10", checked: false },
      ],
    };
  },
  props: {
    disableWidgets: {
      type: Boolean,
      default: false,
    },
    questionObject: {
      type: Object,
      default: function () {
        return {};
      },
    },
  },
  methods: {
    slideStart: function () {},
    slideStop: function () {},
    saveDefaultValue: function () {
      /* eslint-disable */
      if (this.questionObject.widgetConfiguration.hasOwnProperty("defaultValue")) {
        this.saveResponse(this.questionObject.widgetConfiguration.defaultValue);
      } else if (this.questionObject.widgetConfiguration.hasOwnProperty("value")) {
        this.saveResponse(this.questionObject.widgetConfiguration.value);
      }
      /* eslint-enable */
    },
    saveResponseCallback(response) {
      let statusCode = response.data.statusCode;
      if (statusCode == 200) {
        let questionId = response.data.data;
        this.$emit("remove", questionId);
      } else {
        let errorMessage = response.data.message;
        errorMessage += this.$parent.surveyTakerMessage;
        ErrorService.processError("SurveyTakerQuestion.saveResponse", errorMessage, "survery-taker", this);
      }
    },
    async saveResponse(value) {
      if (this.disableWidgets) {
        return;
      }
      let result = {
        surveyId: this.$parent.surveyId,
        questionId: this.questionObject.questionId,
        label: this.questionObject.label,
        value: value,
        initialLoad: this.initialLoad,
      };
      this.$emit("save", result);
      let response = {
        surveyId: this.$parent.surveyId,
        questionId: this.questionObject.questionId,
        value: value,
      };
      if (!this.initialLoad) {
        const params = new URLSearchParams();
        params.append("surveyTakerId", this.$parent.surveyTakerId);
        params.append("response", JSON.stringify(response));
        await axios
          .post(this.baseUrl + "/api/v1/interview/save-response", params)
          .then((response) => this.saveResponseCallback(response))
          .catch((error) => {
            error.message += ErrorService.mergeError(error);
            error.message += this.$parent.surveyTakerMessage;
            ErrorService.processError("SurveyTakerQuestion.saveResponse", error, "survey-taker", this);
          });
      }
    },
    saveResponseEvent: function (event) {
      this.value = event.target.value;
      if (this.isCheckbox) {
        if (event.target.checked) {
          this.values.push(event.target.value);
        } else {
          this.values.splice($.inArray(this.value, this.values), 1);
        }
        this.value = this.values.toString();
      }
      this.saveResponse(this.value);
    },
    setupConfiguration: function () {
      var configuration = {};

      if (this.questionObject.widgetConfiguration != "") {
        this.questionObject.widgetConfiguration = JSON.parse(this.questionObject.widgetConfiguration);
      } else {
        this.questionObject.widgetConfiguration = {};
      }
      if (this.questionObject.widgetType.defaultConfiguration != "") {
        this.questionObject.widgetType.defaultConfiguration = JSON.parse(this.questionObject.widgetType.defaultConfiguration);
      } else {
        this.questionObject.widgetType.defaultConfiguration = {};
      }

      $.extend(configuration, this.questionObject.widgetType.defaultConfiguration);
      $.extend(configuration, this.questionObject.widgetConfiguration);
      this.questionObject.widgetConfiguration = configuration;
      if (this.questionObject.name.includes("Functional")) {
        this.questionObject.widgetConfiguration.options = this.functionalNeedsOptions;
      }
      if (this.questionObject.name.includes("Emotional")) {
        this.questionObject.widgetConfiguration = this.emotionalNeedsOptions;
      }
      if (this.isSlider) {
        this.questionObject.label = this.questionObject.label.split(",");
        this.questionLabel = this.questionObject.label;
      }
    },
    displayResponses: function(){
      if (this.$parent.$parent.entryResponses.length > 0){
        let questionResponse = this.$parent.$parent.entryResponses.find((x) => Number(x.questionId) == Number(this.questionObject.questionId))
        if (typeof questionResponse != "undefined"){
          if (this.isSlider && !isNaN(questionResponse.responseText)){
            this.value = Number(questionResponse.responseText);
            $("#" + this.questionId).bootstrapSlider('setValue', this.value);
            $("#" + this.questionId).bootstrapSlider('disable');
          } else if (this.isRadio && !isNaN(questionResponse.responseText)) {
            for (var t = 0; t < this.questionObject.widgetConfiguration.options.length; t++) {
              if (this.questionObject.widgetConfiguration.options[t].value == questionResponse.responseText){
                this.questionObject.widgetConfiguration.options[t].checked = true;
                this.value = questionResponse.responseText;
              }
            }
          } else if (this.isCheckbox ) {
            this.values = questionResponse.responseText.split(',');
          } else {
            this.value = questionResponse.responseText;
          }
          
        }
      }
    },
    getCheckboxValue: function (value) {
      /* eslint-disable */
      if (this.questionObject.widgetConfiguration.hasOwnProperty("options")) {
        let checkboxOption = this.questionObject.widgetConfiguration.options.find((x) => x.value == value);
        if (typeof checkboxOption != "undefined"){
          return checkboxOption.name;
        }
      }
      /* eslint-enable */
      return "";
    }
  },
  watch: {
    questionObject: function () {
      this.setupConfiguration();
    },
  },
  created() {
    this.setupConfiguration();
  },
  mounted() {
    if (this.isSlider) {
      $("#" + this.questionId).bootstrapSlider();

      const vm = this;
      $("#" + this.questionId).on("slideStop", function (value) {
        vm.saveResponse($(this).val());
      });
    }
    this.displayResponses();
    this.saveDefaultValue();
    this.initialLoad = false;
  },
  computed: {
    readOnly: function() {
      return this.$parent.$parent.readOnly;
    },
    contentDisplay: function () {
      let content = "";
      if (typeof this.questionObject.widgetConfiguration.contentId != "undefined") {
        let contentSection = this.$parent.$parent.$data.pageContent.find((x) => x.contentId == this.questionObject.widgetConfiguration.contentId);
        if (typeof contentSection != "undefined") {
          content = contentSection.contentText;
        }
      }
      return content;
    },
    /* eslint-disable */
    leftDropdownClass: function () {
      if (this.questionObject.widgetConfiguration.hasOwnProperty("displayOnSingleLine") && this.questionObject.widgetConfiguration.displayOnSingleLine) {
        return "col-12";
      } else {
        return "col-4 form-check-label text-right";
      }
      /* eslint-enable */
    },
    rightDropdownClass: function () {
      /* eslint-disable */
      if (this.questionObject.widgetConfiguration.hasOwnProperty("displayOnSingleLine") && this.questionObject.widgetConfiguration.displayOnSingleLine) {
        return "form-group col-12";
      } else {
        return "col-8 form-check form-check-inline";
      }
      /* eslint-enable */
    },
    radioClass: function () {
      /* eslint-disable */
      if (this.isRadio && this.questionObject.widgetConfiguration.hasOwnProperty("number_of_columns")) {
        if (this.questionObject.widgetConfiguration.number_of_columns == 2) {
          return "col-6 form-check-inline";
        }
      }
      if (this.isRadio && this.questionObject.widgetConfiguration.hasOwnProperty("options")) {
        if (this.questionObject.widgetConfiguration.options.length >= 3) {
          return "col-lg-4 col-6 form-check-inline";
        } else if (this.questionObject.widgetConfiguration.options.length == 2) {
          return "col-6 form-check-inline";
        } else if (this.questionObject.widgetConfiguration.options.length == 1) {
          return "col-12 form-check-inline";
        }
      }
      /* eslint-enable */
      return "col-lg-4 col-6 form-check-inline";
    },
    displayLabels: function () {
      /* eslint-disable */
      return this.isRadio && this.questionObject.widgetConfiguration.hasOwnProperty("includeLabels") && this.questionObject.widgetConfiguration.includeLabels;
      /* eslint-enable */
    },
    questionId: function () {
      return "question_" + this.questionObject.questionId;
    },
    questionName: function () {
      return "question_" + this.questionObject.questionId;
    },
    leftLabel: function () {
      if (!this.isSlider) {
        return "";
      }
      if (this.questionObject.name == "Emotional 1" || this.questionObject.name == "Emotional 4") {
        return this.questionLabel[1];
      } else {
        return this.questionLabel[0];
      }
    },
    rightLabel: function () {
      if (!this.isSlider) {
        return "";
      }
      if (this.questionObject.name == "Emotional 1" || this.questionObject.name == "Emotional 4") {
        return this.questionLabel[0];
      } else {
        return this.questionLabel[1];
      }
    },
  },
};
</script>
