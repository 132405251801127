import Vue from "vue";
import "./plugins/axios";
import "./registerServiceWorker";

Vue.config.productionTip = false;
Vue.config.devtools = true;
import Notifications from 'vue-notification';
Vue.use(Notifications);
import store from "./store";
import "./registerServiceWorker";

import App from "./App.vue";
import router from "./router";
import InterviewApp from "./interview-application/InterviewApp.vue";

var dt = require("datatables.net");
require("datatables.net-dt");
//require("datatables.net-colreorder");
require("datatables.net-bs4");
require("datatables.net-buttons");
require("jszip");
import 'datatables.net-responsive/js/dataTables.responsive.min.js';
import "bootstrap";
import 'datatables.net-bs4/css/dataTables.bootstrap4.min.css';
import 'datatables.net-responsive-bs4/css/responsive.bootstrap4.min.css';

if (process.env.NODE_ENV.includes("survey-taker")) {
  var interview = new Vue({
    router,
    render: h => h(InterviewApp)
  }).$mount("#app");
} else {
  var panel = new Vue({
    router,
    store,
    data() {
      return {
        loading: false,
        isMenuExpanded: true,
        showMenu: false
      }
    },
    methods: {
      setLoading(loading) {
        this.loading = loading;
      },
      getLoading() {
        return this.loading;
      },
      toggleHeaderMenu: function() {
        this.showMenu = !this.showMenu;
      },
      toggleLeftNav: function(){

      },
      showModal: function(name){
        if (!this.isMenuExpanded){
          this.toggleLeftNav();
        }
        this.$modal.show(name);
      }
    },
    render: h => h(App)
  }).$mount("#app");
}


