import Vue from "vue";
import VueRouter from "vue-router";

import ContactUs from "../views/ContactUs.vue";

import RespondentDownload from "../views/responses/RespondentDownload.vue";
import Responses from "../views/admin/Responses.vue";

import AddUser from "../views/admin/AddUser.vue";
import UserList from "../views/admin/UserList.vue";
import ContentList from "../views/admin/ContentList.vue";
import FormPreview from "../views/form-design/FormPreview.vue";
import FormDesign from "../views/form-design/FormDesign.vue";
import FormResponses from "../views/responses/FormResponses.vue";
import FormList from "../views/admin/FormList.vue";
import ManageInvitations from "../views/form-design/ManageInvitations.vue";
import EditContent from "../views/admin/EditContent.vue";

import Login from "../views/Login.vue";
import Error from "../views/Error.vue";
import ResetPassword from "../views/ResetPassword.vue";
import AuthService from "../services/auth.service";
import SurveyTaker from "../interview-application/views/SurveyTaker.vue";

Vue.use(VueRouter);

const adminAppRoutes = [
  {
    name: "error",
    path: "/error",
    component: Error,
    meta: {
      title: "404 Page",
      breadcrumb: [],
    },
  },
  {
    name: "logout",
    path: "/logout",
    props: { forceLogout: true },
    component: Login,
    meta: {
      title: "Login",
      breadcrumb: [],
    },
  },
  {
    name: "login",
    path: "/login",
    component: Login,
    meta: {
      title: "Login",
      breadcrumb: [],
    },
    props: true,
  },
  {
    name: "reset-password",
    path: "/reset-password",
    component: ResetPassword,
    meta: {
      title: "Reset Password",
      breadcrumb: [],
    },
    props: true,
  },
  {
    name: "user-settings",
    path: "/user-settings/",
    component: AddUser,
    meta: {
      title: "User Settings",
      breadcrumb: [
        { name: "Home", link: "/form-responses/form-responses" },
        { name: "User Settings", link: "user-settings" },
      ],
    },
    props: true,
  },
  {
    name: "contact-us",
    path: "/contact-us",
    component: ContactUs,
    meta: {
      title: "Contact Us",
      breadcrumb: [{ name: "Home", link: "/form-responses/form-responses" }, { name: "Contact Us" }],
    },
    props: true,
  },
  {
    name: "dashboard",
    path: "/form-responses/form-responses",
    component: Responses,
    meta: {
      title: "Home",
      breadcrumb: [{ name: "Home" }],
    },
    props: true,
  },
  {
    path: "/",
    redirect: "/form-responses/form-responses",
  },
  {
    name: "content-list",
    path: "/admin/content-list",
    component: ContentList,
    props: true,
    meta: {
      title: "Content List",
      breadcrumb: [{ name: "Home", link: "/form-responses/form-responses" }, { name: "Admin" }, { name: "Content List", link: "content-list" }],
      requiresAuth: true,
    },
  },
  {
    name: "edit-content",
    path: "/admin/edit-content",
    component: EditContent,
    props: true,
    meta: {
      title: "Add/Edit Content",
      breadcrumb: [
        { name: "Home", link: "/form-responses/form-responses" },
        { name: "Admin" },
        { name: "Content List", link: "content-list" },
        { name: "Add/Edit Content", link: "add-content" },
      ],
      requiresAuth: true,
    },
  },
  {
    name: "edit-user",
    path: "/admin/edit-user",
    component: AddUser,
    props: true,
    meta: {
      title: "Edit User",
      breadcrumb: [{ name: "Home", link: "/form-responses/form-responses" }, { name: "Admin" }, { name: "User List", link: "user-list" }, { name: "Edit User", link: "edit-user" }],
    },
  },
  {
    name: "duplicate-user",
    path: "/admin/edit-user",
    component: AddUser,
    props: true,
    meta: {
      title: "Edit User",
      breadcrumb: [{ name: "Home", link: "/form-responses/form-responses" }, { name: "Admin" }, { name: "User List", link: "user-list" }, { name: "Duplicate User", link: "edit-user" }],
    },
  },
  {
    name: "add-user",
    path: "/admin/add-user",
    component: AddUser,
    props: true,
    meta: {
      title: "Add User",
      breadcrumb: [{ name: "Home", link: "/form-responses/form-responses" }, { name: "Admin" }, { name: "User List", link: "user-list" }, { name: "Add User", link: "add-user" }],
    },
  },
  {
    name: "user-list",
    path: "/admin/user-list",
    component: UserList,
    props: true,
    meta: {
      title: "User List",
      breadcrumb: [{ name: "Home", link: "/form-responses/form-responses" }, { name: "Admin" }, { name: "User List", link: "user-list" }],
    },
  },
  {
    name: "form-preview",
    path: "/form-design/form-preview",
    component: FormPreview,
    props: true,
    meta: {
      title: "Form Preview",
      breadcrumb: [{ name: "Home", link: "/form-responses/form-responses" }, { name: "Form Design" }, { name: "Form Preview", link: "form-preview" }],
    },
  },
  {
    name: "form-design",
    path: "/form-design/form-design",
    component: FormDesign,
    props: true,
    meta: {
      title: "Form Design",
      breadcrumb: [{ name: "Home", link: "/form-responses/form-responses" }, { name: "Form Design" }, { name: "Form Design", link: "form-design" }],
    },
  },
  {
    name: "manage-invitations",
    path: "/study-design/manage-invitations",
    component: ManageInvitations,
    props: true,
    meta: {
      title: "Manage Forms",
      breadcrumb: [
        { name: "Home", link: "/form-responses/form-responses" },
        { name: "Study Design", link: "respondent-download" },
        { name: "Manage Forms", link: "manage-invitations" },
      ],
      requiresAuth: true,
    },
  },
  {
    name: "form-responses",
    path: "/form-responses/form-responses",
    component: Responses,
    props: true,
    meta: {
      title: "Form Responses",
      breadcrumb: [{ name: "Home", link: "/form-responses/form-responses" }, { name: "Responses" }, { name: "Form Responses", link: "form-responses" }],
    },
  },
  {
    name: "respondent-download",
    path: "/form-responses/respondent-download",
    component: RespondentDownload,
    props: true,
    meta: {
      title: "Form Responses",
      breadcrumb: [{ name: "Home", link: "/form-responses/form-responses" }, { name: "Responses" }, { name: "Respondent Download", link: "respondent-download" }],
    },
  },
  {
    name: "form-list",
    path: "/admin/form-list",
    component: FormList,
    props: true,
    meta: {
      title: "Form List",
      breadcrumb: [{ name: "Home", link: "/form-responses/form-responses" }, { name: "Admin" }, { name: "Form List", link: "form-list" }],
    },
  },
  { path: "*", component: Error },
];

const interviewAppRoutes = [
  {
    name: "survey",
    path: "/survey-code/:surveyCode",
    component: SurveyTaker,
    props: true,
    meta: {
      title: "Form",
    },
  },
  {
    name: "survey with unique code",
    path: "/survey-code/:surveyCode/:uniqueCode",
    component: SurveyTaker,
    props: true,
    meta: {
      title: "Form",
    },
  },
  {
    name: "survey by url",
    path: "/:entryClientId/s/:entrySurveyUrl",
    component: SurveyTaker,
    props: true,
    meta: {
      title: "Form",
    },
  },
  {
    name: "survey by url with unique code",
    path: "/:entryClientId/s/:entrySurveyUrl/:uniqueCode",
    component: SurveyTaker,
    props: true,
    meta: {
      title: "Form",
    },
  },
  
  { path: "*", component: Error },
];
const isInterviewApp = process.env.VUE_APP_BASE_IS_INTERVIEW_APP;
let routes = adminAppRoutes;

if (isInterviewApp) {
  routes = interviewAppRoutes;
}

const router = new VueRouter({
  mode: "history",
  routes,
});

router.afterEach((to, from) => {
  Vue.nextTick(() => {
    document.title = to.meta.title;
  });
});

router.beforeEach((to, from, next) => {
  const publicPages = ["/login", "/logout", "/reset-password", "/error"];
  const authRequired = !publicPages.includes(to.path) && !isInterviewApp;
  const loggedIn = localStorage.getItem("user");
  // trying to access a restricted page + not logged in
  // redirect to login page
  if (authRequired && !loggedIn) {
    next("/login");
  } else if (authRequired && AuthService.hasSessionExpired(loggedIn)) {
    next("/logout");
  } else {
    next();
  }
});

export default router;
